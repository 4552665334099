@import "../../index.scss";

.certificates {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    padding: rem(100) rem(190);

    & > .title {
        font-size: rem(65);
        font-weight: 500;
    }

    & > .subtitle {
        font-size: rem(29);
        max-width: rem(910);
    }

    & > .denominations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: rem(20);
        max-width: rem(910);
        font-size: rem(29);
        color: rgb(143, 133, 128);
    }

    & > .certificatesList {
        display: flex;
        gap: rem(20);
    }

    & > button {
        width: max-content;
        align-self: center;
        background: rgb(240, 233, 229);
        font-size: rem(32);
        border: 0;
        padding: rem(10) rem(120);
        border-radius: rem(30);
    }
}

@media only screen and (max-width: 1632px) {
    .certificates {
        padding: rem(40) rem(100);

        & > .title {
            font-size: rem(30);
        }
    
        & > .subtitle {
            font-size: rem(16);
        }

        & > .content > a {
            width: calc((100% - rem(20)) / 2);
        }
    }
}

@media only screen and (max-width: 992px) {
    .certificates {
        padding: rem(40) rem(10);

        & > .certificatesList {
            flex-direction: column;
        }
    }
}
