@import "../../index.scss";

.services {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    padding: rem(100) rem(190);

    & > .title {
        font-size: rem(45);
        font-weight: 500;
    }

    & > .content {
        display: flex;
        flex-wrap: wrap;
        gap: rem(20);

        & > .contentItem {
            width: calc((100% - rem(40)) / 3);
            box-shadow: 0px 4px 20px 0px rgba(240, 233, 229, 0.5);
            height: 1125px;
            & > .imgWrapper {
                position: relative;
                height: 80%;
                & > img {
                    width: 100%;
                    height: 100%;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(240, 233, 229, 0.6);
                }
            }

            & > .description {
                display: flex;
                flex-direction: column;
                gap: rem(70);
                padding: rem(20);

                & > .title {
                    font-size: rem(32);
                    text-align: center;
                    word-break: break-word;
                    height: 70px;
                }

                & > .more {
                    font-size: rem(29);
                    color: rgb(143, 133, 128);
                    text-align: end;
                    cursor: pointer;
                }
            }
        }
    }
}

@media only screen and (max-width: 1632px) {
    .services {
        padding: rem(40) rem(100);

        & > .title {
            font-size: rem(30);
        }

        & > .content > .contentItem {
            width: calc((100% / 2 - rem(10)));

            & > .description {
                gap: rem(8);

                & > .title {
                    font-size: rem(16);
                }

                & > .more {
                    font-size: rem(14);
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .services {
        padding: rem(40) rem(10);

        & > .content > .contentItem {
            width: 100%
        }
    }
}
