@function rem($pixels) {
    @return ($pixels / 16) * 1rem;
}

body {
    margin: rem(100) 0 0 0;
    font-family: "Raleway";
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    text-decoration: none;
}

.title {
    font-family: "Cormorant";
}

.embla {
    overflow: hidden;
    max-width: 100%;
}

.embla__container {
    display: flex;
    gap: rem(20);
    width: 100%;
    padding: 0 rem(20);
}

.embla__container > div {
    width: max-content;
}
