@import "../../index.scss";

.footer {
    border-top: 1px solid rgb(143, 133, 128);

    & > .grid {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        gap: rem(24);
        padding: rem(40) rem(190);
        justify-content: space-between;

        & > .logo {
            grid-column: 1;
            grid-row: 1;
        }

        & > .column {
            display: flex;
            flex-direction: column;
            font-size: rem(29);
            gap: rem(40);

            & > .title {
                color: rgb(143, 133, 128);
            }

            & > ul {
                display: flex;
                flex-direction: column;
                gap: rem(20);
                margin: 0;
                padding: 0;
                list-style: none;

                a {
                    color: rgb(0, 0, 0);
                }
            }

            &.clinic {
                grid-column: 2;
                grid-row: 1;
            }

            &.contacts {
                grid-column: 3;
                grid-row: 1;
            }
        }

        & > .company {
            grid-column: 1;
            grid-row: 2;
            color: rgba(143, 133, 128, 1);
            font-size: rem(29);
        }

        & > .socialNetworks {
            display: flex;
            gap: rem(16);
            grid-column: 3;
            grid-row: 2;
        }
    }
}

@media only screen and (max-width: 1632px) {
    .footer > .grid {
        padding: rem(40) rem(100);
        grid-template-columns: 1fr 1fr;
        gap: rem(30);

        & > .logo {
            grid-column: 1 / span 2;
            grid-row: 2;
        }

        & > .column.clinic {
            grid-column: 1;
            grid-row: 3;
        }

        & > .column.contacts {
            grid-column: 2;
            grid-row: 3;
        }

        & > .company {
            grid-column: 1 / span 2;
            grid-row: 4;
        }

        & > .socialNetworks {
            margin: 0 auto;
            grid-column: 1 / span 2;
            grid-row: 1;
        }
    }
}

@media only screen and (max-width: 992px) {
    .footer > .grid {
        padding: rem(40) rem(10);

        & > .column {
            font-size: rem(16);
        }

        & > .company {
            font-size: rem(16);
        }

        & > .socialNetworks {
            margin: 0 auto;
        }
    }
}
