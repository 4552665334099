@import "../../index.scss";

.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(26) rem(190);
    background: rgb(240, 233, 229);
    z-index: 9999;

    & > .navList {
        display: flex;
        gap: rem(40);
        padding: 0;
        margin: 0;
        list-style: none;

        & > .navListItem {
            font-size: rem(29);
            color: rgba(0, 0, 0);
            cursor: pointer;
        }
    }

    & > .contactUs {
        display: flex;
        gap: rem(12);
    }

    & > img {
        display: none;
    }
}

@media only screen and (max-width: 1632px) {
    .header {
        padding: rem(18) rem(100);

        & > .navList {
            position: absolute;
            display: none;
            top: 82px;
            left: 0;
            right: 0;
            padding: rem(28) rem(100);
            background: rgb(240, 233, 229);
            align-items: flex-end;

            &.isOpen {
                display: flex;
                flex-direction: column;
            }
        }

        & > .contactUs {
            display: none;
        }

        & > img {
            display: block;
        }
    }
}

@media only screen and (max-width: 992px) {
    .header {
        padding: rem(18) rem(10);
    }
}
