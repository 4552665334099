@import "../../index.scss";

.service {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    padding: rem(100) rem(190);

    & > .services {
        display: flex;
        flex-direction: column;
        gap: rem(40);
        
        & > div {
            display: flex;
            gap: rem(20);
            height: 900px;

            .img { 
                max-width: 1000px;
            }
            
            & > .serviceInfo {
                display: flex;
                flex-direction: column;
                gap: rem(20);

                & > .name {
                    font-size: rem(65);
                }

                & > .description {
                    font-size: rem(29);
                    height: 50%;
                }
            }
        }
    }

    & > .executors {
        display: flex;
        flex-direction: column;
        gap: rem(40);

        & > div {
            display: flex;
        }
    }
}

.title {
    font-size: rem(65);
    font-weight: 500;
}

@media only screen and (max-width: 1632px) {
    .service {
        padding: rem(40) rem(100);

        & > .services > div {
            flex-direction: column;

            & > .serviceInfo > .name {
                font-size: rem(30);
            }

            & > .serviceInfo > .description {
                font-size: rem(16);
            }
        }
    }

    .title {
        font-size: rem(30);
    }
}

@media only screen and (max-width: 992px) {
    .service {
        padding: rem(40) rem(10);
    }
}
