@import "../../index.scss";

.employees {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    padding: rem(100) rem(190);

    & > .title {
        font-size: rem(65);
        font-weight: 500;
    }

    & > .content {
        display: flex;
        flex-wrap: wrap;
        gap: rem(20);

        & > a {
            text-align: center;
            width: calc((100% - rem(280)) / 3);
            padding: rem(40);
            box-shadow: 0px 4px 20px 0px rgba(240, 233, 229, 0.5);
        }
    }
}

@media only screen and (max-width: 1632px) {
    .employees {
        padding: rem(40) rem(100);

        & > .title {
            font-size: rem(30);
        }

        & > .content > a {
            width: calc((100% - rem(180)) / 2);
        }
    }
}

@media only screen and (max-width: 992px) {
    .employees {
        padding: rem(40) rem(10);
        & > .content > a {
            width: 100%;
        }
    }
}
