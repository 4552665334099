@import "../../index.scss";

.employee {
    display: flex;
    flex-direction: column;
    gap: rem(20);
    align-items: center;
    font-size: rem(29);

    & > img {
        width: rem(330);
        max-height: rem(330);
        border-radius: 50%;
        object-fit: cover;
        object-position: center 10%;
    }

    & > .name {
        color: rgb(0, 0, 0);
    }

    & > .position {
        color: rgb(143, 133, 128);
        text-transform: uppercase;
        text-align: center;
    }
}
