@import "../../index.scss";

.contacts {
    display: flex;
    flex-direction: column;
    gap: rem(100);
    padding: rem(100) 0;

    & > div:first-child {
        padding: 0 rem(190);
    }

    & > .clinics {
        display: flex;
        flex-direction: column;
        gap: rem(24);
        padding: 0 rem(190);

        & > .clinicsList {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: rem(20);

            & > div {
                display: flex;
                flex-direction: column;
                gap: rem(16);

                & > .title {
                    font-size: rem(29);
                    margin-bottom: rem(24);
                    color: rgb(143, 133, 128);
                }

                & > img {
                    max-width: rem(500);
                }
            }
        }
    }

    & > .contactUs {
        padding: rem(40) 0;
        background: rgb(240, 233, 229);

        & > div {
            display: flex;
            align-items: center;
            gap: rem(125);
            padding: 0 rem(190);

            & > div:first-child {
                display: flex;
                flex-direction: column;
                gap: rem(20);
                max-width: rem(1045);

                & > .title {
                    line-height: rem(60);
                }
            }

            & > div:last-child {
                display: flex;
                gap: rem(60);
            }
        }
    }

    & > .map {
        display: flex;
        flex-direction: column;
        gap: rem(20);
        padding: 0 rem(190);

        & > .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > div {
                display: flex;
                gap: rem(16);

                & > span {
                    max-width: rem(280);
                    cursor: pointer;
                    color: rgb(0, 0, 0);
                }
            }
        }

        & > div:nth-child(2) > div {
            width: 100% !important;
            height: 754px !important;
        }
    }
}

.title {
    font-size: rem(65);
    line-height: rem(50);
    font-weight: 500;
}

.text {
    font-size: rem(29);
    font-weight: 500;

    &.active {
        color: rgb(143, 133, 128) !important;
    }
}

@media only screen and (max-width: 1632px) {
    .contacts {
        padding: rem(40) 0;
        gap: rem(40);

        & > div:first-child {
            padding: 0 rem(100);
        }

        & > .clinics {
            padding: 0 rem(100);

            & > .clinicsList {
                grid-template-columns: repeat(2, 1fr);

                & > div > .title {
                    font-size: rem(16);
                    line-height: rem(19);
                }

                & > div > img {
                    max-width: 100%;
                }

                & > div:nth-child(1n) {
                    grid-column: 1;
                    grid-row: 2;
                }

                & > div:nth-child(2n) {
                    grid-column: 2;
                    grid-row: 2;
                }

                & > div:nth-child(3n) {
                    grid-column: 1 / 2 span;
                    grid-row: 1;
                }

                & > div:nth-child(4n) {
                    grid-column: 1;
                    grid-row: 4;
                }

                & > div:nth-child(5n) {
                    grid-column: 2;
                    grid-row: 4;
                }

                & > div:nth-child(6n) {
                    grid-column: 1 / 2 span;
                    grid-row: 3;
                }

                & > div:nth-child(7n) {
                    grid-column: 1;
                    grid-row: 6;
                }

                & > div:nth-child(8n) {
                    grid-column: 2;
                    grid-row: 6;
                }

                & > div:nth-child(9n) {
                    grid-column: 1 / 2 span;
                    grid-row: 5;
                }
            }
        }

        & > .contactUs > div {
            flex-direction: column;
            text-align: center;
            gap: rem(20);
            padding: 0 rem(100);
        }

        & > .map {
            gap: rem(20);
            padding: 0 rem(100);

            & > .heading {
                flex-direction: column;
                gap: rem(20);

                & > div {
                    flex-direction: column;
                    align-items: center;
                    gap: rem(10);

                    & > span {
                        max-width: max-content;
                    }
                }
            }
        }
    }

    .title {
        font-size: rem(30);
    }

    .text {
        font-size: rem(16);
    }
}

@media only screen and (max-width: 992px) {
    .contacts {
        & > div:first-child {
            padding: 0 rem(10);
        }

        & > .clinics {
            padding: 0 rem(10);
        }
    }
}
