@import "../../index.scss";

.about {
    display: flex;
    flex-direction: column;
    gap: rem(100);
    padding: rem(100) rem(190);

    & > .content {
        display: flex;
        flex-direction: column;
        gap: rem(100);
        align-items: center;

        & > .heading {
            display: flex;
            flex-direction: column;
            gap: rem(40);
            padding: 0 rem(240);
            text-align: center;
        }

        & > .article {
            display: flex;
            gap: rem(20);

            & > div {
                display: flex;
                flex-direction: column;
                gap: rem(40);

                & > a {
                    align-self: flex-end;
                    margin-top: auto;
                }
            }

            & > img {
                width: 100%;
            }
        }

        & > .virtues {
            display: flex;
            flex-direction: column;
            gap: rem(40);
            align-items: center;
            width: 100%;

            & > .heading {
                display: flex;
                flex-direction: column;
                gap: rem(40);
                max-width: rem(844);
                text-align: center;
            }

            & > .virtuesList {
                display: flex;
                flex-wrap: wrap;
                gap: rem(20);
                width: 100%;

                & > div {
                    display: flex;
                    flex-direction: column;
                    width: calc(100% / 3 - rem(86));
                    opacity: 60%;
                    padding: rem(30) rem(36);
                    text-align: center;
                    font-size: rem(29);
                    background: rgb(240, 233, 229);

                    & > .symbol {
                        font-size: rem(50);
                    }
                }
            }
        }

        & > .list {
            display: flex;
            flex-direction: column;
            gap: rem(40);
            width: 100%;

            & > div {
                display: flex;
                gap: rem(20);

                img {
                    width: rem(180);
                    height: rem(320);
                }

                // & > div {
                //     width: calc(100% / 4 - rem(60));
                //     opacity: 60%;
                //     padding: rem(30) rem(36);
                //     text-align: center;
                //     background: rgb(240, 233, 229);

                //     & > .symbol {
                //         font-size: rem(50);
                //     }
                // }
            }
        }
    }
}

.title {
    font-size: rem(65);
    font-weight: 500;
}

.text {
    font-size: rem(29);
    font-weight: 500;
}

button {
    width: max-content;
    font-size: rem(32);
    border: 2px solid rgb(0, 0, 0);
    padding: rem(10) rem(88) rem(10) rem(36);
    border-radius: rem(30);
    background: transparent;
}

@media only screen and (max-width: 1632px) {
    .about {
        padding: rem(40) rem(100);
        gap: rem(20);

        & > .content {
            gap: rem(10);

            & > .heading {
                padding: 0;
            }

            & > .article {
                flex-direction: column;
            }

            & > .virtues > .virtuesList > div {
                width: calc(100% / 2 - rem(82));
                margin: 0 auto;

                & > span {
                    font-size: rem(16);
                }
            }
        }
    }

    .title {
        font-size: rem(30);
    }

    .text {
        font-size: rem(16);
    }

    button {
        font-size: rem(16);
    }
}

@media only screen and (max-width: 992px) {
    .about {
        padding: rem(40) rem(10);
    }
}
