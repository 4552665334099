@import "../../index.scss";

.table {
    width: 100%;
    border-spacing: 0;

    & > thead > tr {
        & > th {
            text-align: center;
            font-size: rem(32);
            border-bottom: 1px solid rgb(143, 133, 128);

            &:not(:nth-child(2)) {
                padding: rem(20);
                background: rgb(240, 233, 229);
            }

            &:first-child {
                text-align: left;
            }
        }
    }

    & > tbody > tr {
        & > td {
            text-align: center;
            font-size: rem(32);

            &:first-child {
                text-align: left;
            }

            &:nth-child(2) {
                width: rem(20);
            }

            &:not(:nth-child(2)) {
                padding: rem(20);
                background: rgb(240, 233, 229);
            }
        }

        &:not(:last-child) > td {
            border-bottom: 1px solid rgb(143, 133, 128);
        }
    }
}

.controllerList {
    display: none;
}

@media only screen and (max-width: 1632px) {
    .table > thead > tr > :not(.active) {
        display: none;
    }

    .table > tbody > tr > :not(.active) {
        display: none;
    }

    .table > thead > tr > th {
        font-size: rem(16);
    }

    .table > tbody > tr > td {
        font-size: rem(16);
    }

    .controllerList {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(10);
        list-style: none;

        & > .controllerItem {
            font-size: rem(20);
            cursor: pointer;

            &.active {
                color: rgb(143, 133, 128);
            }
        }
    }
}
