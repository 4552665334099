@import "../../index.scss";

.employee {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    padding: rem(100) rem(190);

    & > .profile {
        display: flex;
        gap: rem(20);

        & > img {
            object-fit: cover;
        }

        & > .employeeInfo {
            display: flex;
            flex-direction: column;
            gap: rem(20);

            & > .name {
                font-size: rem(65);
            }

            & > .position {
                font-size: rem(29);
                color: rgb(143, 133, 128);
                text-transform: uppercase;
            }

            & > .description {
                font-size: rem(29);
                white-space: pre-wrap;
            }

            & > .shedule {
                font-size: rem(29);
                color: rgb(143, 133, 128);

                & > .span {
                    color: rgb(0, 0, 0);
                }
            }
        }
    }

    & > .education {
        margin-top: rem(20);

        & > .title {
            font-size: rem(29);
            color: rgb(143, 133, 128);
        }

        & > .educationList {
            display: flex;
            flex-direction: column;
            gap: rem(10);
            padding: rem(20) 0 0 rem(36);
            margin: 0;
            font-size: rem(29);
        }
    }

    & > .proceduresList {
        & > .title {
            font-size: rem(29);
            color: rgb(143, 133, 128);
        }
    }
}

@media only screen and (max-width: 1632px) {
    .employee {
        padding: rem(40) rem(100);

        & > .profile > .employeeInfo > .name {
            font-size: rem(30);
        }

        & > .profile > .employeeInfo > .position {
            font-size: rem(16);
        }

        & > .profile > .employeeInfo > .description {
            font-size: rem(16);
            white-space: pre-wrap;
        }

        & > .profile > .employeeInfo > .shedule {
            font-size: rem(16);
        }

        & > .education > .title {
            font-size: rem(16);
        }

        & > .education > .educationList {
            font-size: rem(16);
        }
    }
}

@media only screen and (max-width: 992px) {
    .employee {
        padding: rem(40) rem(10);
    }
}
