@import "../../index.scss";

.breadcrumbs {
    display: flex;
    gap: rem(40);

    & > a {
        font-size: rem(32);
        color: rgb(129, 122, 118);

        &:last-child {
            color: rgb(0, 0, 0);
        }
    }
}

@media only screen and (max-width: 1632px) {
    .breadcrumbs > a {
        font-size: rem(16);
    }
}
