.appointment {
    position: fixed;
    display: flex;
    align-items: center;
    text-align: center;
    background: rgb(255, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: Verdana;
    height: 110px;
    width: 110px;
    bottom: 4%;
    right: 3%;
    border-radius: 100px;
    text-decoration: none;
}