@import "../../index.scss";

.home {
    display: flex;
    flex-direction: column;
    gap: rem(100);
    padding-bottom: rem(100);

    & > .description {
        position: relative;

        & > img {
            width: 100%;
        }

        & > .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: rem(226);
            padding: rem(226) calc((100% - rem(1700)) / 2);

            & > .title {
                text-align: center;
                font-size: rem(150);
                line-height: rem(120);
                color: rgb(255, 255, 255);
                width: rem(1252);
            }

            & > .virtues {
                display: flex;
                gap: rem(20);
                margin-top: rem(140);

                & > div {
                    display: flex;
                    flex-direction: column;
                    width: calc((25% - rem(20)));
                    opacity: 60%;
                    padding: rem(30) rem(36);
                    text-align: center;
                    font-size: rem(29);
                    background: rgb(240, 233, 229);

                    & > .symbol {
                        font-size: rem(50);
                    }
                }
            }
        }

        & > .slogan {
            position: relative;
            display: block;
            font-size: rem(65);
            text-align: center;
        }

        & > .subtitle {
            position: relative;
            bottom: 0;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(240, 233, 229, 0.6);
        }

        & > .subdescription {
            position: relative;
            padding: rem(32);
            background: linear-gradient(180deg, rgba(240, 233, 229, 0) 0%, #f0e9e5 45.83%);

            & > span {
                display: block;
                width: rem(890);
                margin: 0 auto;
                text-align: center;
                font-size: rem(29);
            }
        }
    }

    & > .services {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(24);
        padding: 0 rem(190);

        & > span {
            grid-column: 1;
            grid-row: 1;
        }

        & > a > button {
            grid-column: 2;
            grid-row: 1;
            width: max-content;
            align-self: center;
            background: transparent;
            font-size: rem(32);
            padding: rem(10) rem(120) rem(10) rem(32);
            border: 2px solid rgb(0, 0, 0);
            border-radius: rem(30);
        }

        & > .content {
            display: flex;
            flex-wrap: wrap;
            gap: rem(20);
            grid-column: 1 / span 2;
            grid-row: 2;

            & > .contentItem {
                position: relative;
                width: calc((100% - rem(40)) / 3);
                text-decoration: none;
                color: rgb(0, 0, 0);
                height: 1125px;
                & > span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding: rem(16) rem(20);
                    background: rgb(255, 255, 255);
                    font-size: rem(32);
                    font-weight: 600;
                    text-align: center;
                    word-break: break-word;
                }

                & > img {
                    width: 100%;
                    height: 100%;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(240, 233, 229, 0.6);
                }
            }
        }
    }

    & > .prices {
        display: flex;
        flex-direction: column;
        gap: rem(24);
        width: rem(1540);
        margin: 0 auto;
    }

    & > .wrapper {
        display: flex;
        flex-direction: column;
        gap: rem(20);
        width: rem(1540);
        margin: 0 auto;

        & > .list {
            max-width: rem(1540);
            overflow: hidden;
            display: flex;
            gap: rem(20);
        }
    }

    & > .clinics {
        display: flex;
        flex-direction: column;
        gap: rem(24);
        padding: 0 rem(190);

        & > .clinicsList {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: rem(20);

            & > div {
                display: flex;
                flex-direction: column;
                gap: rem(16);

                & > span {
                    font-size: rem(29);
                }

                & > .title {
                    font-size: rem(29);
                    margin-bottom: rem(24);
                    color: rgb(143, 133, 128);
                }

                & > img {
                    max-width: rem(500);
                }
            }
        }
    }
}

.title {
    font-size: rem(65);
    font-weight: 500;
}

@media only screen and (max-width: 1632px) {
    .home {
        gap: rem(40);

        & > .description {
            display: none;

            & .content > .virtues {
                flex-wrap: wrap;
            }
        }

        & > .services {
            padding: 0 rem(100);
            & > .content {
                gap: rem(10);

                & > .contentItem {
                    width: calc((100% - rem(10)) / 2);

                    & > span {
                        font-size: rem(16);
                    }
                }
            }

            & > a > button {
                font-size: rem(16);
            }
        }

        & > .clinics {
            padding: 0 rem(100);

            & > .clinicsList {
                grid-template-columns: repeat(2, 1fr);

                & > div > img {
                    max-width: 100%;
                }

                & > div > .title {
                    font-size: rem(16);
                }

                & > div > span {
                    font-size: rem(16);
                }

                & > div:nth-child(1n) {
                    grid-column: 1;
                    grid-row: 2;
                }

                & > div:nth-child(2n) {
                    grid-column: 2;
                    grid-row: 2;
                }

                & > div:nth-child(3n) {
                    grid-column: 1 / 2 span;
                    grid-row: 1;
                }

                & > div:nth-child(4n) {
                    grid-column: 1;
                    grid-row: 4;
                }

                & > div:nth-child(5n) {
                    grid-column: 2;
                    grid-row: 4;
                }

                & > div:nth-child(6n) {
                    margin-top: rem(64);
                    grid-column: 1 / 2 span;
                    grid-row: 3;
                }

                & > div:nth-child(7n) {
                    grid-column: 1;
                    grid-row: 6;
                }

                & > div:nth-child(8n) {
                    grid-column: 2;
                    grid-row: 6;
                }

                & > div:nth-child(9n) {
                    margin-top: rem(64);
                    grid-column: 1 / 2 span;
                    grid-row: 5;
                }
            }
        }
    }

    .title {
        font-size: rem(30);
    }
}

@media only screen and (max-width: 992px) {
    .home > .services {
        grid-template-columns: 1fr;
        padding: 0 rem(10);

        & > span {
            grid-column: 1;
            grid-row: 1;
        }

        & > a {
            text-align: end;

            & > button {
                grid-column: 1;
                grid-row: 3;
            }
        }

        & > .content {
            grid-column: 1;
            grid-row: 2;

            & > .contentItem {
                width: 100%;
            }
        }
    }

    .home > .clinics {
        padding: 0 rem(10);

        & > .clinicsList {
            grid-template-columns: 1fr;

            & > div > .title {
                margin-bottom: rem(10);
            }

            & > div:nth-child(1n) {
                grid-column: 1;
                grid-row: 2;
            }

            & > div:nth-child(2n) {
                grid-column: 1;
                grid-row: 3;
            }

            & > div:nth-child(3n) {
                grid-column: 1;
                grid-row: 1;
            }

            & > div:nth-child(4n) {
                grid-column: 1;
                grid-row: 5;
            }

            & > div:nth-child(5n) {
                grid-column: 1;
                grid-row: 6;
            }

            & > div:nth-child(6n) {
                margin-top: rem(32);
                grid-column: 1;
                grid-row: 4;
            }

            & > div:nth-child(7n) {
                grid-column: 1;
                grid-row: 8;
            }

            & > div:nth-child(8n) {
                grid-column: 1;
                grid-row: 9;
            }

            & > div:nth-child(9n) {
                margin-top: rem(32);
                grid-column: 1;
                grid-row: 7;
            }
        }
    }
}
